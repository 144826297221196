// Generated by Framer (f4b44ab)

import { addFonts, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["GDlDDy9qg"];

const serializationHash = "framer-6itLI"

const variantClassNames = {GDlDDy9qg: "framer-v-vfnq21"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transition1 = {damping: 30, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const animation = {opacity: 1, rotate: 2, scale: 1, transition: transition1}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, P5IqwfCRrE0p7NPqIH, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "GDlDDy9qg", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-6itLI", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={"https://framer.com/projects/new?duplicate=GaQVto9O2kYyaFsclTbl&via=cristianmielu"} openInNewTab><motion.a {...restProps} className={`${cx("framer-vfnq21", className)} framer-141bsi7`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"GDlDDy9qg"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, ...style}}><motion.div className={"framer-1d5tog4"} layoutDependency={layoutDependency} layoutId={"Gqti1eID_"} style={{backgroundColor: "var(--token-9c7f6279-e9bd-4641-bc08-198beefe2286, rgb(26, 26, 26))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10}} whileHover={animation}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7QXplcmV0IE1vbm8tcmVndWxhcg==", "--framer-font-family": "\"Azeret Mono\", monospace", "--framer-font-size": "11px", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-0376b963-b1d3-4bef-907c-5739b65993ee, rgb(241, 233, 218)))"}}>Get FREE Template</motion.p></React.Fragment>} className={"framer-u2du8c"} fonts={["GF;Azeret Mono-regular"]} layoutDependency={layoutDependency} layoutId={"GQHGGJstY"} style={{"--extracted-r6o4lv": "var(--token-0376b963-b1d3-4bef-907c-5739b65993ee, rgb(241, 233, 218))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={P5IqwfCRrE0p7NPqIH} verticalAlignment={"top"} withExternalLayout/></motion.div></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-6itLI [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6itLI .framer-141bsi7 { display: block; }", ".framer-6itLI .framer-vfnq21 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 36px; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: 142px; }", ".framer-6itLI .framer-1d5tog4 { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 36px; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 142px; }", ".framer-6itLI .framer-u2du8c { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-6itLI .framer-vfnq21, .framer-6itLI .framer-1d5tog4 { gap: 0px; } .framer-6itLI .framer-vfnq21 > *, .framer-6itLI .framer-1d5tog4 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-6itLI .framer-vfnq21 > :first-child, .framer-6itLI .framer-1d5tog4 > :first-child { margin-left: 0px; } .framer-6itLI .framer-vfnq21 > :last-child, .framer-6itLI .framer-1d5tog4 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 142
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 */
const Framerzc79Fp2L1: React.ComponentType<Props> = withCSS(Component, css, "framer-6itLI") as typeof Component;
export default Framerzc79Fp2L1;

Framerzc79Fp2L1.displayName = "Get Template Button";

Framerzc79Fp2L1.defaultProps = {height: 36, width: 142};

addFonts(Framerzc79Fp2L1, [{family: "Azeret Mono", style: "normal", url: "https://fonts.gstatic.com/s/azeretmono/v17/3XF5ErsiyJsY9O_Gepph-FvtTQgMQUdNekSfnPVh17aa-5s3AA.woff2", weight: "400"}])